export const loginPaths = [
  {
    condition: (state) => true,
    flow: 'login',
    steps: ['login']
  },
  {
    condition: (state) => state.didVerifyEmailWithVerificationToken,
    flow: 'signup',
    steps: ['signup', 'choosePassword']
  },
  {
    condition: (state) => true,
    flow: 'signup',
    steps: ['signup', 'authorizationCode', 'choosePassword']
  },
  {
    condition: (state) => true,
    flow: 'passwordLogin',
    steps: ['loginWithPassword']
  },
  {
    condition: (state) => true,
    flow: 'passwordReset',
    steps: ['passwordResetEnterEmail', 'authorizationCode', 'choosePassword']
  }
]
