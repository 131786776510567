<template>
  <div>
    <login-header>
      <template slot="subtitle">Enter your email to access your Celtra account.</template>
    </login-header>
    <div class="inputs-container">
      <input-element ref="email" theme="light" label="Email" v-model="emailModel" @keyup.enter="handleEnter"></input-element>
      <div class="login-button-container">
        <dialog-button size="small" class="login-dialog-button" @click="determineLoginMethod" :disabled="!isEmailValid" :loading="isLoginButtonLoading">Sign in</dialog-button>
      </div>
    </div>
    <login-footer
      v-if="isSelfSignUpAvailable"
      @click="beginFlow('signup')">
      <span>Don't have an account? Sign up </span>
    </login-footer>
  </div>
</template>

<script>
import Input from 'design-system/components/Forms/Input/Input.vue'
import DialogButton from 'design-system/components/Buttons/Button/DialogButton.vue'
import LoginHeader from '@/components/LoginHeader.vue'
import LoginFooter from '@/components/LoginFooter.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'LoginStep',
  components: {
    inputElement: Input,
    LoginHeader,
    LoginFooter,
    DialogButton
  },
  computed: {
    ...mapState('login', ['email', 'isLoginButtonLoading']),
    ...mapGetters('login', ['isEmailValid', 'isSelfSignUpAvailable']),
    emailModel: {
      get: function () {
        return this.email
      },
      set: function (email) {
        this.setEmail(email)
      }
    }
  },
  mounted () {
    this.$refs.email.focus()
  },
  methods: {
    ...mapActions('login', [
      'determineLoginMethod',
      'setEmail',
      'beginFlow'
    ]),
    handleEnter () {
      if (this.isEmailValid) {
        this.determineLoginMethod()
      }
    }
  }
}
</script>

<style lang="less">
@import '~@/shared/styles.less';
</style>

<style scoped lang="less">
@import (reference) '~@/design-system/src/less/variables.less';

.login-button-container {
  margin-top: 40px;
}
</style>
