<template>
  <div>
    <login-header>
      <template slot="title">Choose password</template>
    </login-header>
    <div class="inputs-container">
      <input-element theme="light" label="Password" type="password" v-model="passwordModel"></input-element>
      <input-element theme="light" label="Confirm password" type="password" class="password-input" v-model="confirmPasswordModel" @keyup.enter="onEnterHandler"></input-element>
      <div class="error-message" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="error-message" v-else-if="isConfirmPasswordDifferent">
        Passwords do not match
      </div>
      <div class="login-button-container">
        <dialog-button size="small" class="login-dialog-button" @click="savePassword" :disabled="!isSavePasswordEnabled" :loading="isLoginButtonLoading">Continue</dialog-button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from 'design-system/components/Forms/Input/Input.vue'
import DialogButton from 'design-system/components/Buttons/Button/DialogButton.vue'
import LoginHeader from '@/components/LoginHeader.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    inputElement: Input,
    DialogButton,
    LoginHeader
  },
  computed: {
    ...mapState('login', ['password', 'confirmPassword', 'errorMessage', 'isLoginButtonLoading']),
    ...mapGetters('login', ['isSavePasswordEnabled', 'isConfirmPasswordDifferent']),
    passwordModel: {
      get: function () {
        return this.password
      },
      set: function (password) {
        this.setPassword(password)
      }
    },
    confirmPasswordModel: {
      get: function () {
        return this.confirmPassword
      },
      set: function (password) {
        this.setConfirmPassword(password)
      }
    }
  },
  methods: {
    ...mapActions('login', [
      'savePassword',
      'setPassword',
      'setConfirmPassword'
    ]),
    onEnterHandler () {
      if (this.isSavePasswordEnabled) {
        this.savePassword()
      }
    }
  }
}
</script>

<style lang="less">
  @import '~@/shared/styles.less';
</style>

<style scoped lang="less">
.login-button-container {
  margin-top: 40px;
}

.password-input {
  margin-top: 16px;
}
</style>
