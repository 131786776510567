const defaultSizes      = ['extra-small', 'small', 'medium', 'large']
const defaultThemes     = ['dark', 'light']
const defaultDimensions = ['x', 'y']
const defaultRangeKeys  = ['start', 'end']
const defaultPositions  = ['left', 'right']

export const sizeValidator = (value, sizes = defaultSizes) => sizes.includes(value)

export const themeValidator = (value, themes = defaultThemes) => themes.includes(value)

export const axisValidator = (value, dimensions = defaultDimensions) => dimensions.includes(value)

export const rangeValidator = (value, keys = defaultRangeKeys) => typeof value === 'object' && keys.every(key => Object.keys(value).includes(key))

export const numberOrRangeValidator = (value, keys = defaultRangeKeys)  => typeof value === 'object' && rangeValidator(value, keys) || typeof value === 'number'

export const positionValidator = (value, positions = defaultPositions) => positions.includes(value)
