<template>
    <div class="login-header">
      <img src="../assets/celtra-logo-icon-black.svg" alt="Celtra" class="login-header__logo">
      <span class="login-header__title"><slot name="title">Sign in</slot></span>
      <span class="login-header__subtitle" v-if="$slots.subtitle"><slot name="subtitle"></slot></span>
    </div>
</template>

<script>
export default {
  name: 'LoginHeader'
}
</script>

<style scoped lang="less">
@import (reference) '~@/design-system/src/less/variables.less';

.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 8px;

  &__logo {
    height: 56px;
    margin-bottom: 24px;
  }

  @media(min-width:600px) {
    // Only show this logo when in small popup, when auth is opened in full sized window celtra logo is on top left
    .login-header__logo { display: none; }
  }

  &__title {
    color: @slate;
    font-size: 32px;
    .bold-font();
    margin-bottom: 8px;
  }

  &__subtitle {
    color: @dust;
    font-size: 14px;
    .regular-font();
  }
}
</style>
