import Vue from 'vue'
import Vuex from 'vuex'
import api from '../apiClient.js'

Vue.use(Vuex)

function getState () {
  return {
    firstName: null,
    lastName: null,
    email: null,
    oldPassword: null,
    newPassword: null,
    confirmNewPassword: null,
    passwordChangeErrorNewPassword: null,
    passwordChangeErrorOldPassword: null,
    isProfileUpdateButtonLoading: false,
    isProfileUpdateSuccessful: false,
    emailChangeError: null,
    pendingEmail: null,
    domainSecurityConstraint: null,
    recentlyUsedClients: []
  }
}

export default {
  state: getState(),
  namespaced: true,
  mutations: {
    RESET_STATE (state) {
      Object.assign(state, JSON.parse(JSON.stringify(getState())))
    },
    SET_FIRST_NAME (state, name) {
      state.firstName = name
    },
    SET_LAST_NAME (state, name) {
      state.lastName = name
    },
    SET_EMAIL (state, email) {
      state.email = email
    },
    SET_OLD_PASSWORD (state, password) {
      state.oldPassword = password
    },
    SET_NEW_PASSWORD (state, password) {
      state.newPassword = password
    },
    SET_CONFIRM_NEW_PASSWORD (state, password) {
      state.confirmNewPassword = password
    },
    SET_IS_CHANGE_PASSWORD_BUTTON_LOADING (state, isLoading) {
      state.isChangePasswordButtonLoading = isLoading
    },
    SET_IS_PROFILE_UPDATE_BUTTON_LOADING (state, isLoading) {
      state.isProfileUpdateButtonLoading = isLoading
    },
    SET_PASSWORD_CHANGE_ERROR_NEW_PASSWORD (state, error) {
      state.passwordChangeErrorNewPassword = error
    },
    SET_PASSWORD_CHANGE_ERROR_OLD_PASSWORD (state, error) {
      state.passwordChangeErrorOldPassword = error
    },
    SET_EMAIL_CHANGE_ERROR (state, error) {
      state.emailChangeError = error
    },
    SET_PENDING_EMAIL (state, email) {
      state.pendingEmail = email
    },
    SET_IS_PROFILE_UPDATE_SUCCESSFUL (state, isSuccessful) {
      state.isProfileUpdateSuccessful = isSuccessful
    },
    SET_DOMAIN_SECURITY_CONSTRAINT (state, domainSecurityConstraint) {
      state.domainSecurityConstraint = domainSecurityConstraint
    },
    SET_RECENTLY_USED_CLIENTS (state, clients) {
      state.recentlyUsedClients = clients
    }
  },
  actions: {
    initialize ({ rootState, commit, dispatch }) {
      commit('SET_FIRST_NAME', rootState.me.firstName)
      commit('SET_LAST_NAME', rootState.me.lastName)
      commit('SET_EMAIL', rootState.me.email)
      commit('SET_PENDING_EMAIL', rootState.me.pendingEmail)
      if (rootState.urlParams.emailChangeKey) {
        dispatch('confirmEmailChange')
      }
      return Promise.all([
        dispatch('loadDomainSecurityConstraint', rootState.me.email.split('@')[1]),
        dispatch('loadRecentlyUsedClients')
      ])
    },
    loadRecentlyUsedClients ({ commit }) {
      return api.get('/recentlyUsedClients')
        .then(recentlyUsedClients => commit('SET_RECENTLY_USED_CLIENTS', recentlyUsedClients))
    },
    loadDomainSecurityConstraint ({ commit }, domain) {
      return api.get(`/domainSecurityConstraints/${domain}`).then(response => commit('SET_DOMAIN_SECURITY_CONSTRAINT', response))
    },
    returnToClient ({ state, rootState }) {
      const client = rootState.clients.find(client => client.id === rootState.urlParams.returnToClient)
      window.location.href = client.applicationUrl
    },
    confirmEmailChange ({ state, rootState, commit, dispatch }) {
      return api.post('/confirmEmailChangeRequest', {
        emailChangeKey: rootState.urlParams.emailChangeKey
      }).then(() => {
        commit('SET_EMAIL', state.pendingEmail)
        commit('SET_PENDING_EMAIL', null)
        commit('SET_IS_PROFILE_UPDATE_SUCCESSFUL', true)
        return dispatch('getMe', {}, { root: true })
      })
    },
    saveProfileChanges ({ state, commit, getters, rootState, dispatch }) {
      commit('SET_EMAIL_CHANGE_ERROR', null)
      commit('SET_IS_PROFILE_UPDATE_BUTTON_LOADING', true)
      commit('SET_IS_PROFILE_UPDATE_SUCCESSFUL', false)
      const requests = []
      if (getters.isProfileChanged) {
        requests.push(api.put('/me', {
          firstName: state.firstName,
          lastName: state.lastName
        }))
      }
      if (getters.isEmailChanged) {
        requests.push(api.post('/emailChangeRequest', {
          email: state.email
        }).then(() => {
          commit('SET_EMAIL', rootState.me.email)
          commit('SET_PENDING_EMAIL', state.email)
        }).catch(response => {
          commit('SET_EMAIL_CHANGE_ERROR', response.message)
        }))
      }
      if (getters.isPasswordChanged) {
        commit('SET_PASSWORD_CHANGE_ERROR_NEW_PASSWORD', null)
        commit('SET_PASSWORD_CHANGE_ERROR_OLD_PASSWORD', null)
        requests.push(api.post('/passwordUpdateRequest', {
          oldPassword: state.oldPassword,
          newPassword: state.newPassword
        }).then(() => {
          commit('SET_OLD_PASSWORD', null)
          commit('SET_NEW_PASSWORD', null)
          commit('SET_CONFIRM_NEW_PASSWORD', null)
        }).catch(response => {
          if (response.field === 'newPassword') {
            commit('SET_PASSWORD_CHANGE_ERROR_NEW_PASSWORD', response.message)
          } else {
            commit('SET_PASSWORD_CHANGE_ERROR_OLD_PASSWORD', response.message)
          }
          return Promise.reject(response)
        }))
      }
      return Promise.all(requests)
        .then(() => dispatch('getMe', {}, { root: true }))
        .then(() => commit('SET_IS_PROFILE_UPDATE_SUCCESSFUL', true))
        .finally(() => commit('SET_IS_PROFILE_UPDATE_BUTTON_LOADING', false))
    },
    setEmail ({ commit }, email) {
      commit('SET_EMAIL', email)
    },
    setOldPassword ({ commit }, password) {
      commit('SET_OLD_PASSWORD', password)
    },
    setNewPassword ({ commit }, password) {
      commit('SET_NEW_PASSWORD', password)
    },
    setConfirmNewPassword ({ commit }, password) {
      commit('SET_CONFIRM_NEW_PASSWORD', password)
    },
    setFirstName ({ commit }, name) {
      commit('SET_FIRST_NAME', name)
    },
    setLastName ({ commit }, name) {
      commit('SET_LAST_NAME', name)
    },
    resetState ({ commit }) {
      commit('RESET_STATE')
    }
  },
  getters: {
    isEmailValid: (state) => !isEmpty(state.email) && /\S+@\S+\.\S+/.test(state.email),
    isOldPasswordEmpty: (state) => isEmpty(state.oldPassword),
    isNewPasswordValid: (state) => !isEmpty(state.newPassword),
    isConfirmNewPasswordValid: (state) => state.newPassword === state.confirmNewPassword,
    isPasswordChangeValid: (state, getters) => getters.isNewPasswordValid && getters.isConfirmNewPasswordValid,
    isPasswordChanged: (state, getters) => !isEmpty(state.newPassword),
    isEmailChanged: (state, getters, rootState) => state.email !== rootState.me.email,
    isProfileChanged: (state, getters, rootState) => rootState.me.firstName !== state.firstName || rootState.me.lastName !== state.lastName,
    isProfileValid: (state) => !isEmpty(state.firstName) && !isEmpty(state.lastName),
    canSaveChanges: (state, getters) => {
      if (getters.isEmailChanged || getters.isProfileChanged || getters.isPasswordChanged) {
        if (getters.isEmailChanged && !getters.isEmailValid) {
          return false
        }
        if (getters.isPasswordChanged && (!getters.isNewPasswordValid || getters.isOldPasswordEmpty || !getters.isConfirmNewPasswordValid)) {
          return false
        }
        if (getters.isProfileChanged && !getters.isProfileValid) {
          return false
        }
        return true
      }
      return false
    },
    isSsoEnabled: (state) => state.domainSecurityConstraint && state.domainSecurityConstraint.isSso,
    canReturnToClient: (state, getters, rootState) => {
      if (rootState.urlParams.returnToClient) {
        const client = rootState.clients.find(client => client.id === rootState.urlParams.returnToClient)
        return client && client.applicationUrl
      }
      return false
    }
  }
}

function isEmpty (v) { return !v || v === '' }
