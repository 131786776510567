<template>
  <div>
    <login-header>
      <template slot="subtitle">Enter your email to access your Celtra account.</template>
    </login-header>
    <div class="inputs-container">
      <input-element theme="light" label="Email" type="text" v-model="emailModel" @keyup.enter="handleEnter"></input-element>
      <input-element theme="light" ref="password" label="Password" type="password" class="password-input" v-model="passwordModel" @keyup.enter="handleEnter"></input-element>
      <recaptcha ref="recaptcha" v-if="isRecaptchaRequiredForLogin" :site-key="platform.recaptchaSiteKey" @verify="setRecaptchaToken"></recaptcha>
      <div class="error-message" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="subtitle"><span class="subtitle__link" @click="beginFlow('passwordReset')">Forgot your password?</span></div>
      <div class="login-button-container">
        <dialog-button size="small" class="login-dialog-button" @click="loginWithPasswordHandler" :disabled="!isLoginWithPasswordEnabled" :loading="isLoginButtonLoading">Sign in</dialog-button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from 'design-system/components/Forms/Input/Input.vue'
import DialogButton from 'design-system/components/Buttons/Button/DialogButton.vue'
import Recaptcha from '@/components/Recaptcha.vue'
import LoginHeader from '@/components/LoginHeader.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'LoginWithPasswordStep',
  components: {
    inputElement: Input,
    DialogButton,
    LoginHeader,
    Recaptcha
  },
  computed: {
    ...mapState(['platform']),
    ...mapState('login', ['email', 'isLoginButtonLoading', 'errorMessage']),
    ...mapGetters('login', ['isEmailValid', 'isLoginWithPasswordEnabled', 'isRecaptchaRequiredForLogin']),
    emailModel: {
      get: function () {
        return this.email
      },
      set: function (email) {
        this.setEmail(email)
      }
    },
    passwordModel: {
      get: function () {
        return this.password
      },
      set: function (password) {
        this.setPassword(password)
      }
    }
  },
  mounted () {
    this.$refs.password.focus()
  },
  methods: {
    ...mapActions('login', [
      'loginWithPassword',
      'setEmail',
      'setPassword',
      'setRecaptchaToken',
      'beginFlow'
    ]),
    handleEnter () {
      if (this.isLoginWithPasswordEnabled) {
        this.loginWithPasswordHandler()
      }
    },
    loginWithPasswordHandler () {
      this.loginWithPassword().then(() => {
        if (this.$refs.recaptcha) {
          this.$refs.recaptcha.reset()
        }
      })
    }
  }
}
</script>

<style lang="less">
  @import '~@/shared/styles.less';
</style>

<style scoped lang="less">
@import (reference) '~@/design-system/src/less/variables.less';

.subtitle {
  text-align: center;
  margin-top: 16px;
  font-size: 14px;
  .regular-font();
  color: @charcoal;

  &__link {
    color: @blue;
    cursor: pointer;
  }
}

.login-button-container {
  margin-top: 40px;
}

.password-input {
  margin-top: 16px;
}
</style>
