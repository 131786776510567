export default {
  get (path) {
    return makeRequest('GET', path)
  },
  post (path, data) {
    return makeRequest('POST', path, JSON.stringify(data))
  },
  put (path, data) {
    return makeRequest('PUT', path, JSON.stringify(data))
  },
  delete (path) {
    return makeRequest('DELETE', path)
  }
}

function makeRequest (method, path, body = {}) {
  const config = {
    method
  }
  if (['post', 'put'].includes(method.toLowerCase())) {
    config.headers = { 'Content-type': 'application/json; charset=UTF-8' }
    config.body = body
  }
  return fetch('/api' + path, config)
    .then(response => {
      if (!response.ok) {
        return response.json().catch(() => {
          console.error('Unknown error')
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject({ message: 'Unknown error' })
        }).then(json => {
          console.error(json)
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(json)
        })
      }
      return response.json().catch(() => {
        return null
      })
    })
}
