<template>
    <div class="icon-wrapper" @click="$emit('click', $event)" @keyup="$emit('keyup', $event)" @keydown="$emit('keydown', $event)">
        <!-- 'loading': loader spinning icon -->
        <svg v-if="name === 'loading'" class="spin" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path class="path" d="M15 30C6.7 30 0 23.3 0 15c0-4.7 2.3-9.2 6-12l2.4 3.2C5.6 8.2 4 11.5 4 15c0 6 4.9 11 11 11s11-4.9 11-11c0-5.7-4.4-10.5-10-11l.3-4C24 .7 30 7.2 30 15c0 8.3-6.7 15-15 15z" />
        </svg>

        <svg v-else-if="name === 'left-arrow'" viewBox="0 0 22 17" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M.293 7.707L8 0l1.414 1.414-6 6H21a1 1 0 1 1 0 2H3.414l6 6L8 16.828.293 9.121a.999.999 0 0 1 0-1.414z" fill-rule="nonzero" />
        </svg>

        <svg v-else-if="name === 'right-arrow'" viewBox="0 0 22 17" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M21.707 7.707L14 0l-1.414 1.414 6 6H1a1 1 0 1 0 0 2h17.586l-6 6L14 16.828l7.707-7.707a.999.999 0 0 0 0-1.414z" fill-rule="nonzero" />
        </svg>

        <!-- Used in the Chip component -->
        <!-- 'clear-small': X sign, bold -->
        <svg v-else-if="name === 'x-bold'" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M6.4 0L4 2.4 1.6 0 0 1.6 2.4 4 0 6.4 1.6 8 4 5.6 6.4 8 8 6.4 5.6 4 8 1.6z" />
        </svg>

        <svg v-else-if="name === 'alpha'" viewBox="0 0 300 92" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 16C0 7.16345 7.16344 0 16 0H284C292.837 0 300 7.16344 300 16V76C300 84.8366 292.837 92 284 92H16C7.16345 92 0 84.8366 0 76V16Z" fill="#FE664E" />
            <path d="M69.852 70L66.155 58.358H49.115L45.417 70H35L51.838 22H63.77L80.642 70H69.852ZM57.517 31.18L51.299 50.84H63.969L57.753 31.18H57.517V31.18ZM118.504 61.684H97.297V22.001H87.147V70.001H118.504V61.683V61.684ZM126.084 22.001H146.318C156.268 22.001 163.124 28.554 163.124 38.433C163.124 48.279 156.034 54.865 145.814 54.865H136.237V70H126.087V22L126.084 22.001ZM136.234 29.951V47.013H143.596C149.409 47.013 152.804 43.953 152.804 38.466C152.804 33.01 149.444 29.95 143.628 29.95H136.234V29.951ZM212.817 70.001V22.001H202.667V41.493H180.821V22H170.671V70H180.821V49.775H202.667V70H212.817V70.001ZM254.21 70.001L250.513 58.359H233.473L229.775 70.001H219.355L236.195 22.001H248.125L264.999 70.001H254.209H254.21ZM241.875 31.181L235.657 50.841H248.327L242.11 31.181H241.875V31.181Z" fill="white" />
        </svg>

        <svg v-else-if="name === 'alpha-circle'" class="badge-beta" xmlns="http://www.w3.org/2000/svg">
            <path class="rest" d="M13.037 16h2.076l-3.18-9.16H9.679L6.5 16h1.923l.705-2.184h3.218L13.037 16zM10.69 8.745h.114l1.136 3.592h-2.4l1.15-3.592zM21.467 14.33H17.39V6.84h-1.917V16h5.993v-1.67zM22.006 6.84V16h1.917v-2.844h1.809c1.93 0 3.269-1.263 3.269-3.148 0-1.904-1.289-3.168-3.174-3.168h-3.821zm1.917 1.575h1.396c1.105 0 1.733.558 1.733 1.6 0 1.027-.64 1.586-1.739 1.586h-1.39V8.415zM37.526 16V6.84h-1.917v3.695h-4.126V6.84h-1.917V16h1.917v-3.828h4.126V16h1.917zM44.432 16h2.075l-3.18-9.16h-2.253L37.894 16h1.923l.704-2.184h3.219L44.432 16zm-2.35-7.255h.115l1.136 3.592h-2.399l1.149-3.592z" fill="#fff" />
            <path class="greek" d="M20 14.612c-.134.02-.269.02-.403.02-1.345 0-2.442-.838-2.935-2.39.538-1.805 1.367-2.917 3.092-2.917h.224V7.521c-.135-.021-.292-.021-.426-.021-1.725 0-2.98 1.028-3.674 2.685h-.067c-.83-1.636-2.129-2.181-3.316-2.181-1.77 0-3.495 1.28-3.495 3.86 0 2.328 1.523 4.111 3.786 4.111 1.232 0 2.442-.629 3.181-2.097l.068-.021c.739 1.636 2.016 2.643 3.808 2.643H20v-1.888zm-7.326-.357c-1.232 0-2.263-.818-2.263-2.307 0-1.616 1.188-2.245 2.308-2.245 1.3 0 2.173.88 2.576 2.035-.448 1.51-1.21 2.517-2.62 2.517z" fill="#fff" />
        </svg>

        <svg v-else-if="name === 'beta'" viewBox="0 0 235 92" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 16C0 7.16345 5.61137 0 12.5333 0H222.467C229.389 0 235 7.16344 235 16V76C235 84.8366 229.389 92 222.467 92H12.5333C5.61137 92 0 84.8366 0 76V16Z" fill="#FE664E" />
            <path d="M56.618 70H35V22H56.2514C65.2924 22 70.9638 26.624 70.9638 33.976C70.9638 39.232 67.0276 43.6216 61.9228 44.3872V44.6528C68.4618 45.152 73.2664 49.9088 73.2664 56.2624C73.2664 64.7104 66.8939 70 56.6188 70H56.618ZM45.075 29.4848V41.9584H52.6145C57.985 41.9584 61.0552 39.5968 61.0552 35.5384C61.0552 31.6792 58.3532 29.484 53.6486 29.484H45.075V29.4848ZM45.075 62.5168H54.0496C59.8547 62.5168 62.9897 60.0544 62.9897 55.464C62.9897 50.9728 59.7546 48.5784 53.8159 48.5784H45.075V62.5144V62.5168ZM112.452 61.6848H90.632V49.5752H111.218V41.8592H90.632V30.2832H112.452V22H80.5578V70H112.452V61.6832V61.6848ZM143.031 70H132.956V30.2832H118.877V22H157.143V30.2832H143.032V70H143.031ZM189.289 70L185.62 58.3576H168.705L165.035 70H154.694L171.408 22H183.251L200 70H189.291H189.289ZM177.045 31.1808L170.875 50.84H183.452L177.281 31.1808H177.046H177.045Z" fill="white" />
        </svg>

        <svg v-else-if="name === 'beta-circle'" class="badge-beta" xmlns="http://www.w3.org/2000/svg">
            <path class="greek" d="M13.784 11.576h.457c1.2 0 1.854.54 1.854 1.548 0 .857-.578 1.441-1.422 1.441-1.035 0-1.676-.666-1.676-1.967V9.392c0-1.123.45-1.707 1.333-1.707.78 0 1.27.476 1.27 1.256 0 .768-.495 1.225-1.384 1.225h-.432v1.41zm-2.628-1.917v8.664h1.847v-3.396h.115c.317.724 1.091 1.18 2.056 1.18 1.708 0 2.812-1.097 2.812-2.792 0-1.371-.666-2.228-1.967-2.545v-.115c.78-.336 1.27-1.098 1.27-1.967 0-1.562-1.182-2.514-2.984-2.514-2.038 0-3.149 1.206-3.149 3.485z" fill="#fff" />
            <path class="rest" d="M15.117 16c1.955 0 3.168-1.016 3.168-2.622 0-1.18-.883-2.094-2.095-2.183v-.114c.94-.14 1.657-.984 1.657-1.956 0-1.415-1.06-2.285-2.838-2.285h-4.005V16h4.113zm-2.196-7.693h1.6c.914 0 1.44.438 1.44 1.168 0 .736-.565 1.161-1.6 1.161h-1.44v-2.33zm0 6.227V11.93h1.663c1.13 0 1.746.445 1.746 1.282 0 .857-.597 1.32-1.702 1.32h-1.707zM26.086 14.362h-4.151v-2.228h3.916v-1.517h-3.916V8.478h4.15V6.84h-6.067V16h6.068v-1.638zM32.243 16V8.478h2.749V6.84h-7.414v1.638h2.748V16h1.917zM41.58 16h2.076l-3.18-9.16h-2.253L35.043 16h1.923l.704-2.184h3.219L41.58 16zm-2.348-7.255h.114l1.136 3.592h-2.399l1.149-3.592z" fill="#fff" />
        </svg>

        <svg v-else-if="name === 'caret'" viewBox="0 0 12 5" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M6 5l6-5H0z" />
        </svg>

        <svg v-else-if="name === 'bars'" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M1 0v10H0V0h1zm3 0v10H3V0h1zm3 0v10H6V0h1z" />
        </svg>

        <svg v-else-if="name === 'check-bold'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path class="path" d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
        </svg>

        <svg v-else-if="name === 'dropdown-arrow'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M4 6h8l-4 4-4-4z" />
        </svg>

        <svg v-else-if="name === 'kebab'" viewBox="0 0 4 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M2 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-5C.9 5 0 4.1 0 3s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
        </svg>

        <svg v-else-if="name === 'pencil-edit'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M8.424 3L13 7.576l-8.112 8.112c-.208.208-.416.312-.728.312H1.04C.416 16 0 15.584 0 14.96v-3.12c0-.312.104-.52.312-.728L8.424 3zm7.253.554c.43.43.43 1.077 0 1.508L13.739 7 9 2.262 10.938.323c.431-.43 1.077-.43 1.508 0l3.23 3.23z" />
        </svg>

        <svg v-else-if="name === 'pencil-edit-inverted'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
        </svg>

        <svg v-else-if="name === 'pencil-edit-inverted-small'" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M7 0l3 3-7 7H0V7l7-7zM1.414 7L3 8.586 8.5 3 7 1.5 1.414 7z" />
        </svg>

        <svg v-else-if="name === 'qr-code'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M5.083 1H1v4.083h4.083V1zm0 9.917H1V15h4.083v-4.083zM10.917 1H15v4.083h-4.083V1zM15 6.25h-1.167v3.5h-2.916v1.167H15V6.25zm-4.083 5.833H15V15h-1.167v-1.75h-1.75V15h-1.166v-2.917zM9.75 1h-3.5v1.167h2.333v2.916H9.75V1zM8.583 6.25h3.5v2.333h-1.166V7.417H9.75V9.75H7.417v1.167H9.75V15H8.583v-2.917H6.25v-3.5h2.333V6.25zm-1.166 7H6.25V15h1.167v-1.75zM3.333 8.583h1.75V9.75h-1.75V8.583zm4.084-5.25H6.25V6.25H1v3.5h1.167V7.417h5.25V3.333z" />
        </svg>

        <svg v-else-if="name === 'strategy-report'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path class="path" d="M15 0h-3v2h2v12H2V2h2V0H1C.4 0 0 .4 0 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm-4 0H5v4h6V0zm2 3.697v3.606l-2.68 4.019-4.543-2.524L3 12.5V9.167l2.223-2.965L9.68 8.678 13 3.698z" />
        </svg>

        <svg v-else-if="name === 'refresh'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M4.576 3.818c2.022-2.015 5.428-2.015 7.449 0 .745.743 1.277 1.804 1.49 2.863l2.128-.318c-.213-1.59-.957-2.97-2.021-4.03-2.873-2.863-7.556-2.863-10.536 0L.746 0 0 6.788l6.81-.743-2.234-2.227zM16 9.212l-6.812.743 2.235 2.227c-2.021 2.015-5.427 2.015-7.449 0-.744-.743-1.277-1.804-1.49-2.863l-2.127.318c.212 1.59.957 2.968 2.021 4.029 1.49 1.486 3.299 2.121 5.214 2.121 1.916 0 3.831-.742 5.215-2.121L15.149 16l.85-6.788z" />
        </svg>

        <svg v-else-if="name === 'filter'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M15 0H1C.4 0 0 .4 0 1v2c0 .3.1.6.4.8L6 8.5V15c0 .4.2.8.6.9.1.1.3.1.4.1.3 0 .5-.1.7-.3l2-2c.2-.2.3-.4.3-.7V8.5l5.6-4.7c.3-.2.4-.5.4-.8V1c0-.6-.4-1-1-1z" />
        </svg>

        <svg v-else-if="name === 'preview'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" fill-rule="evenodd" d="M8 8c-1.12 0-2-.88-2-2s.88-2 2-2 2 .88 2 2-.88 2-2 2 M16 11a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v10zM2 6c1.652 2.67 4 4 6 4s4.352-1.337 6-4c-1.649-2.665-4-4-6-4S3.651 3.332 2 6 M2 15a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v1H2v-1z" />
        </svg>

        <svg v-else-if="name === 'warning'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M15.216 13.529L8.882 1.654a1 1 0 0 0-1.765 0L.784 13.529A1 1 0 0 0 1.667 15h12.667a1 1 0 0 0 .882-1.471 M8 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2 m1-3H7V6h2v4z" />
        </svg>

        <svg v-else-if="name === 'clear-circle'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" fill-rule="evenodd" d="M2 8c0-3.3 2.7-6 6-6s6 2.7 6 6-2.7 6-6 6-6-2.7-6-6 m8-3l1 1-1.98 2L11 10l-1 1-1.99-2.02L6 11l-1-1 2.02-2L5 6l1-1 2.02 2L10 5z" />
        </svg>

        <svg v-else-if="name === 'left'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M3 8l7.207 7.207 1.414-1.414L5.828 8l5.793-5.793L10.207.793" />
        </svg>

        <svg v-else-if="name === 'right'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M13 8l-7.207 7.207-1.414-1.414L10.172 8 4.379 2.207 5.793.793" />
        </svg>

        <svg v-else-if="name === 'top'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M8 3L15.2071 10.2071L13.7929 11.6213L8 5.82843L2.20711 11.6213L0.792892 10.2071L8 3Z" />
        </svg>

        <svg v-else-if="name === 'down'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M8 13L.793 5.793l1.414-1.414L8 10.172l5.793-5.793 1.414 1.414L8 13z" />
        </svg>

        <svg v-else-if="name === 'close'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M9.41421 8.00001L14.364 12.9498L12.9497 14.364L7.99999 9.41422L3.05025 14.364L1.63603 12.9498L6.58578 8.00001L1.63603 3.05026L3.05025 1.63605L7.99999 6.58579L12.9497 1.63605L14.364 3.05026L9.41421 8.00001Z" />
        </svg>

        <svg v-else-if="name === 'sort'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M12 8.23125L11.1642 7.44375L8.59701 9.8625V4H7.40299V9.8625L4.83582 7.44375L4 8.23125L8 12L12 8.23125Z" />
        </svg>

        <svg v-else-if="name === 'share'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M5.25 10.7l.7.7 1.4-1.4-.7-.7c-1-1-1-2.6 0-3.6l3-3c1-1 2.6-1 3.6 0s1 2.6 0 3.6l-.7.7 1.4 1.4.7-.7c.9-.9 1.35-2.05 1.35-3.2 0-1.15-.45-2.3-1.35-3.2-.9-.8-2-1.3-3.2-1.3-1.2 0-2.3.4-3.2 1.3l-3 3c-1.8 1.8-1.8 4.6 0 6.4 m-3.9 4c.9.8 2 1.3 3.2 1.3 1.2 0 2.4-.5 3.2-1.3l3-3c1.8-1.8 1.8-4.6 0-6.4l-.7-.7L8.65 6l.7.7c1 1 1 2.6 0 3.6l-3 3c-1 1-2.6 1-3.6 0s-1-2.6 0-3.6l.7-.7-1.4-1.4-.7.7c-1.8 1.8-1.8 4.6 0 6.4z" />
        </svg>

        <svg v-else-if="name === 'comments'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M15 0a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-3v4l-6-4H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h14z" />
        </svg>

        <svg v-else-if="name === 'delete'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" fill-rule="evenodd" d="M12 4h3v2H1V4h3V2c0-.6.4-1 1-1h6c.6 0 1 .4 1 1v2 m-2 0V3H6v1h4 M13 7H3v7a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V7z" />
        </svg>

        <svg v-else-if="name === 'remove-item'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" fill-rule="evenodd" d="M9 8l2-2-1-1-2 2-2-2-1 1 2 2-2 2 1 1 2-2 2 2 1-1-2-2 M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0-1.25a5.75 5.75 0 1 0 0-11.5 5.75 5.75 0 0 0 0 11.5z" />
        </svg>

        <svg v-else-if="name === 'duplicate'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M1 12h10a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1zm4 4h10a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-1v9a1 1 0 0 1-1 1H4v1a1 1 0 0 0 1 1z" />
        </svg>

        <svg v-else-if="name === 'shortcuts'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" fill-rule="evenodd" d="M15 2a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14zm-4.5 2h-2v2h2V4zM2 7h2.5v2H2V7zm5.5 0h-2v2h2V7zM5 10v2H2v-2h3zm5 0H6v2h4v-2zm4 0v2h-3v-2h3zm-3.5-3h-2v2h2V7zm1 0H14v2h-2.5V7zm0-3H14v2h-2.5V4zM2 4h2.5v2H2V4zm5.5 0h-2v2h2V4z" />
        </svg>

        <svg v-else-if="name === 'support'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 13c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1 m1.5-4.6c-.5.3-.5.4-.5.6v1H7V9c0-1.3.8-1.9 1.4-2.3.5-.3.6-.4.6-.7 0-.6-.4-1-1-1-.4 0-.7.2-.9.5l-.5.9-1.7-1 .5-.9C5.9 3.6 6.9 3 8 3c1.7 0 3 1.3 3 3 0 1.4-.9 2-1.5 2.4z" />
        </svg>

        <svg v-else-if="name === 'import'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-4v-2h3V3H2v10h3v2H1a1 1 0 0 1-1-1V2 m9 13V9h3L8 5 4 9h3v6h2z" />
        </svg>

        <svg v-else-if="name === 'download'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M9 1v6h3l-4 4-4-4h3V1h2 m7 13a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-4h2v3h12v-3h2v4z" />
        </svg>

        <svg v-else-if="name === 'resolved-static'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" fill-rule="evenodd" d="M16 8c0-4.411-3.589-8-8-8S0 3.589 0 8s3.589 8 8 8 8-3.589 8-8 M4 8.414l3.414 3.414 5.414-5.414L11.414 5l-4 4-2-2L4 8.414z" />
        </svg>

        <svg v-else-if="name === 'star'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M10 6L8 .256 6 6H0l4.89 3.828-1.922 5.916L8 12.088l5.032 3.656-1.922-5.916L16 6h-6z" />
        </svg>

        <svg v-else-if="name === 'settings'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M15.135 6.784a2 2 0 0 1-1.23-2.969c.322-.536.225-.998-.094-1.316l-.31-.31c-.318-.318-.78-.415-1.316-.094A2 2 0 0 1 9.216.865C9.065.258 8.669 0 8.219 0h-.438c-.45 0-.845.258-.997.865a2 2 0 0 1-2.969 1.23c-.536-.322-.999-.225-1.317.093l-.31.31c-.318.318-.415.781-.093 1.317a2 2 0 0 1-1.23 2.969C.26 6.935 0 7.33 0 7.781v.438c0 .45.258.845.865.997a2 2 0 0 1 1.23 2.969c-.322.536-.225.998.094 1.316l.31.31c.319.319.782.415 1.316.094a2 2 0 0 1 2.969 1.23c.151.607.547.865.997.865h.438c.45 0 .845-.258.997-.865a2 2 0 0 1 2.969-1.23c.535.321.997.225 1.316-.094l.31-.31c.318-.318.415-.78.094-1.316a2 2 0 0 1 1.23-2.969c.607-.151.865-.547.865-.997v-.438c0-.451-.26-.846-.865-.997 M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
        </svg>

        <svg v-else-if="name === 'undo-arrow'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M3.852 3.152A7.026 7.026 0 0 1 8.8 1.1c3.859 0 7 3.14 7 7a6.96 6.96 0 0 1-1.326 4.101l-1.621-1.173c.62-.856.947-1.868.947-2.928 0-2.757-2.243-5-5-5a5.023 5.023 0 0 0-3.535 1.465L7.4 6.7 1 7.4 1.7 1l2.152 2.152zM1.897 9.265a6.996 6.996 0 0 0 1.405 3.168l1.571-1.239a4.982 4.982 0 0 1-1.003-2.26l-1.973.331zM4.658 13.744a7.015 7.015 0 0 0 2.976 1.259l.332-1.972a4.992 4.992 0 0 1-2.122-.898l-1.186 1.61zM9.965 15.003l-.33-1.973a4.99 4.99 0 0 0 2.209-.964l1.219 1.586a6.996 6.996 0 0 1-3.098 1.35z" />
        </svg>

        <svg v-else-if="name === 'clock'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" fill-rule="evenodd" d="M16 8c0-4.4-3.6-8-8-8S0 3.6 0 8s3.6 8 8 8 8-3.6 8-8 M9 7V4H7v5h5V7H9z" />
        </svg>

        <svg v-else-if="name === 'audience'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M10.2 11.4l-2.7-2C8.4 8.7 9 7.7 9 6.5v-.8C9 3.8 7.6 2.1 5.7 2 3.7 1.9 2 3.5 2 5.5v1c0 1.2.6 2.2 1.5 2.9L.8 11.5c-.5.4-.8 1-.8 1.6V15c0 .6.4 1 1 1h9c.6 0 1-.4 1-1v-2c0-.6-.3-1.2-.8-1.6zM15.1 6.4l-1.8-1.2c.4-.4.7-1 .7-1.7v-.9C14 1.4 12.6 0 11.5 0S9.7.5 9.2 1.4c1.1 1 1.8 2.4 1.8 4v1c0 .9-.2 1.8-.6 2.5 0 0 1.2.9 1.2 1H15c.6 0 1-.4 1-1v-.8c0-.7-.3-1.3-.9-1.7z" />
        </svg>

        <svg v-else-if="name === 'language'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M7.548 9.55l.634-1.805a8.59 8.59 0 0 1-1.875-.828A7.423 7.423 0 0 0 7.416 5.3c.311-.611.541-1.26.684-1.931.589 0 .979.02 1.419.06V1.62c-.482.052-.966.075-1.45.07H5.687v-.61c0-.205.02-.41.06-.61H3.738c.041.204.061.412.06.62v.6H1.589A12.32 12.32 0 0 1 .14 1.62v1.809c.5-.04.839-.06 1.429-.06a8.8 8.8 0 0 0 .69 1.709 8.7 8.7 0 0 0 1.289 1.829A10.892 10.892 0 0 1 0 8.216c.42.53.745 1.128.959 1.769a12.816 12.816 0 0 0 3.979-1.839 11.58 11.58 0 0 0 2.61 1.404 m-4.06-6.181h2.649a5.635 5.635 0 0 1-1.25 2.418 5.7 5.7 0 0 1-1.399-2.418zM13.717 16l-.725-2.379H9.349L8.624 16H6.341L9.868 5.965h2.591L16 16h-2.283 m-1.231-4.156l-1.131-3.657a10.295 10.295 0 0 1-.181-.67c-.15.583-.581 2.025-1.292 4.327h2.604z" />
        </svg>

        <svg v-else-if="name === 'calendar'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M15 2h-2V1c0-.6-.4-1-1-1h-1c-.6 0-1 .4-1 1v1H6V1c0-.6-.4-1-1-1H4c-.6 0-1 .4-1 1v1H1c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V3c0-.6-.4-1-1-1zm-1 12H2V7h12v7z" />
        </svg>

        <svg v-else-if="name === 'save-static'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M8 2C4.7 2 2 4.7 2 8s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6z" fill="#17E387" />
        </svg>

        <svg v-else-if="name === 'plus-circle'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm4 9H9v3H7V9H4V7h3V4h2v3h3v2z" />
        </svg>

        <svg v-else-if="name === 'plus'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M9 7h7v2H9v7H7V9H0V7h7V0h2v7z" />
        </svg>

        <svg v-else-if="name === 'plus-big'" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M12 1H10V10H1V12H10V21H12V12H21V10H12V1Z" />
        </svg>

        <svg v-else-if="name === 'plus-bold'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M10 0H6v6H0v4h6v6h4v-6h6V6h-6z" />
        </svg>

        <svg v-else-if="name === 'minus-bold'" viewBox="0 0 16 4" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M0 0h16v4H0z" />
        </svg>

        <svg v-else-if="name === 'search'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M12.7 11.2298C13.6 10.0373 14.1 8.64596 14.1 7.0559C14.1 3.18012 11 0 7.1 0C3.2 0 0 3.18012 0 7.0559C0 10.9317 3.2 14.1118 7.1 14.1118C8.7 14.1118 10.2 13.6149 11.3 12.7205L14.3 15.7019C14.5 15.9006 14.8 16 15 16C15.2 16 15.5 15.9006 15.7 15.7019C16.1 15.3043 16.1 14.7081 15.7 14.3106L12.7 11.2298ZM7.1 12.0248C4.3 12.0248 2 9.83851 2 7.0559C2 4.27329 4.3 1.98758 7.1 1.98758C9.9 1.98758 12.2 4.27329 12.2 7.0559C12.2 9.83851 9.9 12.0248 7.1 12.0248Z" />
        </svg>

        <svg v-else-if="name === 'mail'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M15 1H1C0.4 1 0 1.4 0 2V3.4L8 7.9L16 3.5V2C16 1.4 15.6 1 15 1 M7.5 9.9L0 5.7V14C0 14.6 0.4 15 1 15H15C15.6 15 16 14.6 16 14V5.7L8.5 9.9C8.22 10.04 7.78 10.04 7.5 9.9Z" />
        </svg>

        <svg v-else-if="name === 'list-search'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M4 13H1a1 1 0 0 1 0-2h3v2zM15 3H1a1 1 0 0 1 0-2h14a1 1 0 1 1 0 2zM4 8H1a1 1 0 0 1 0-2h3v2zm11.707 5.293l-2.274-2.274c.368-.61.563-1.307.567-2.019 0-2.206-1.794-4-4-4S6 6.794 6 9s1.794 4 4 4c.74 0 1.424-.215 2.019-.567l2.274 2.274 1.414-1.414zM10 11c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z" />
        </svg>

        <svg v-else-if="name === 'check'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M6.871 9.95L12.821 4l1.414 1.414-7.364 7.364L2 7.907l1.414-1.414L6.871 9.95z" />
        </svg>

        <svg v-else-if="name === 'minus'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M14 7v2H2V7h12z" />
        </svg>

        <svg v-else-if="name === 'pie-chart'" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <path class="path" d="M7 .062A8.001 8.001 0 008 16a7.985 7.985 0 006.233-2.984L7.669 8.944C7.285 8.708 7 8.5 7 8V.062m8.283 11.254L9 7.452V.062a8.001 8.001 0 016.283 11.254z" />
        </svg>

        <svg v-else-if="name === 'analytics'" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <path class="path" d="M7 .062A8.001 8.001 0 008 16a7.985 7.985 0 006.233-2.984L7.669 8.944C7.285 8.708 7 8.5 7 8V.062m8.283 11.254L9 7.452V.062a8.001 8.001 0 016.283 11.254z" />
        </svg>

        <svg v-else-if="name === 'database'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M1 3c0 2.1 3.5 3 7 3s7-.9 7-3-3.5-3-7-3-7 .9-7 3zm0 10c0 2.1 3.5 3 7 3s7-.9 7-3V6.5C13.4 7.5 11 8 8 8s-5.4-.5-7-1.5V13z" />
        </svg>

        <svg v-else-if="name === 'unlinked'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <path class="path" d="M8.23 7H12v2H9.308L12 14l-1.732 1-3.23-6H4V7h1.96L3.268 2 5 1l3.23 6 M9 3h7v10h-3v-2h1V5H9V3zM2 11V5h1V3H0v10h7v-2H2z" />
        </svg>

        <svg v-else-if="name === 'location'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" fill-rule="evenodd" d="M6.02 13.468C6.818 14.394 7.547 15.243 8 16c.454-.757 1.183-1.606 1.98-2.532C11.811 11.335 14 8.788 14 6c0-3.3-2.7-6-6-6S2 2.7 2 6c0 2.789 2.188 5.335 4.02 7.468zM6 6c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2z" />
        </svg>

        <svg v-else-if="name ==='add-ball'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="plus" d="M3 2h10v11H3z" />
            <path class="path circle" d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm4 9H9v3H7V9H4V7h3V4h2v3h3v2z" />
        </svg>

        <svg v-else-if="name === 'creative'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M15 0H1C.4 0 0 .4 0 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM6 4c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm-3 8l2-4 2 2 3-4 3 6H3z" />
        </svg>

        <svg v-else-if="name === 'creativeLiftStudy'" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M4 7c1.7 0 3-1.3 3-3S5.7 1 4 1 1 2.3 1 4s1.3 3 3 3zm0-4c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zM12 9c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm0 4c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z M15 1H9v2h2.6L1.3 13.3l1.4 1.4L13 4.4V7h2V1z" />
        </svg>

        <svg v-else-if="name === 'splitTest'" width="16" height="16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M13 1.5c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zM3 8.5c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z M3 1.5L6 7H0l3-5.5zM13 14.5L16 9h-6l3 5.5zM7 1h2v14H7z" />
        </svg>

        <svg v-else-if="name === 'responsive'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M14 6h2V0h-6v2h2.586L9.293 5.293l1.414 1.414L14 3.414V6zM0 6h2V3.414l3.293 3.293 1.414-1.414L3.414 2H6V0H0v6zM3.414 14l3.293-3.293-1.414-1.414L2 12.586V10H0v6h6v-2H3.414zM14 12.586l-3.293-3.293-1.414 1.414L12.586 14H10v2h6v-6h-2v2.586z"/>
        </svg>

        <svg v-else-if="name === 'fixed-size'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M16 16v-6h-2v4h-4v2h6zM0 16h6v-2H2v-4H0v6zM6 0H0v6h2V2h4V0zM10 0v2h4v4h2V0h-6zM11 8a3 3 0 11-6 0 3 3 0 016 0z"/>
        </svg>

        <svg v-else-if="name === 'locked-aspect-ratio'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M0 6h2V3.414l3.293 3.293 1.414-1.414L3.414 2H6V0H0v6zM14 12.586l-3.293-3.293-1.414 1.414L12.586 14H10v2h6v-6h-2v2.586z"/>
        </svg>

        <svg v-else-if="name === 'document-page'" viewBox="0 0 27 30" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M18.75 0l7.5 7.5h-7.5V0zM15 11.25h11.25v16.875c0 1.125-.75 1.875-1.875 1.875h-22.5C.75 30 0 29.25 0 28.125V1.875C0 .75.75 0 1.875 0H15v11.25z" />
        </svg>

        <svg v-else-if="name === 'external-link'" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M14.6125 1H8.4875V2.75H12.5125L7 8.2625L8.225 9.4875L13.7375 3.975V8H15.4875V1.875C15.4875 1.35 15.1375 1 14.6125 1Z" fill="#444450" />
            <path class="path" d="M13.25 15.125H1.875C1.35 15.125 1 14.775 1 14.25V2.875C1 2.35 1.35 2 1.875 2H5.375V3.75H2.75V13.375H12.375V10.75H14.125V14.25C14.125 14.775 13.775 15.125 13.25 15.125Z" fill="#525266" />
        </svg>

        <svg v-else-if="name === 'image'" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path class="path" fill-rule="evenodd" clip-rule="evenodd" d="M22 .355C22 .142 21.858 0 21.645 0H.355C.142 0 0 .142 0 .355v21.29c0 .213.142.355.355.355h21.29c.213 0 .355-.142.355-.355V.355zM3.194 2.839h15.613c.213 0 .354.142.354.355v9.9l-3.726-2.378a.323.323 0 0 0-.425.036l-4.046 4.045-4.009-2.662c-.142-.106-.32-.07-.461.036l-3.655 3.655V3.194c0-.213.142-.355.355-.355zm6.43 7.611a1.93 1.93 0 0 0 1.926-1.925A1.93 1.93 0 0 0 9.625 6.6 1.93 1.93 0 0 0 7.7 8.525a1.93 1.93 0 0 0 1.925 1.925z" />
        </svg>

        <svg v-else-if="name === 'video'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <!-- dynamic ID to prevent collisions on the same page -->
            <mask :id="`video_mask_id_${_uid}`" fill="#fff">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8c0-4.4 3.6-8 8-8s8 3.6 8 8-3.6 8-8 8-8-3.6-8-8zm11 0L7 5v6l4-3z" />
            </mask>
            <path class="path" d="M7 5l.6-.8L6 3v2h1zm4 3l.6.8 1.067-.8-1.067-.8-.6.8zm-4 3H6v2l1.6-1.2L7 11zM8-1C3.048-1-1 3.048-1 8h2c0-3.848 3.152-7 7-7v-2zm9 9c0-4.952-4.048-9-9-9v2c3.848 0 7 3.152 7 7h2zm-9 9c4.952 0 9-4.048 9-9h-2c0 3.848-3.152 7-7 7v2zm-9-9c0 4.952 4.048 9 9 9v-2c-3.848 0-7-3.152-7-7h-2zm7.4-2.2l4 3 1.2-1.6-4-3-1.2 1.6zM8 11V5H6v6h2zm2.4-3.8l-4 3 1.2 1.6 4-3-1.2-1.6z" :mask="`url(#video_mask_id_${_uid})`" />
        </svg>

        <svg v-else-if="name === 'reset'" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M3.05195 3.05202C4.34995 1.75802 6.12695 1.00002 7.99995 1.00002C11.859 1.00002 15 4.14002 15 8.00002C15 11.86 11.859 15 7.99995 15V13C10.757 13 13 10.757 13 8.00002C13 5.24302 10.757 3.00002 7.99995 3.00002C6.66195 3.00002 5.39295 3.54202 4.46495 4.46502L6.59995 6.60002L0.199951 7.30002L0.899951 0.900024L3.05195 3.05202Z" />
            <path class="path" d="M5.99994 12.5C5.99994 13.8807 4.88065 15 3.49994 15C2.11923 15 0.999939 13.8807 0.999939 12.5C0.999939 11.1193 2.11923 10 3.49994 10C4.88065 10 5.99994 11.1193 5.99994 12.5Z" />
        </svg>

        <svg v-else-if="name === 'text'" viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
            <path class="stroke-path" d="M.5 2.5v-2h12v2M6.5.5v13M4.5 13.5h4M9.5 7.5v-1h6v1M12.5 6.5v7M14 13.5h-3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <svg v-else-if="name === 'shape'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <rect class="stroke-path" x="0.5" y="0.5" width="15" height="15" rx="1.5" />
        </svg>

        <svg v-else-if="name === 'choice-feed'" xmlns="http://www.w3.org/2000/svg" viewBox="2 2 24 24">
            <g class="path" fill-rule="evenodd">
                <path d="M17.967 21.859a.488.488 0 0 1-.52.134L4.673 17.81l-2.66 2.92 15.442 5.055 8.559-9.403-2.358-.772-5.688 6.248z"/>
                <path d="M17.963 17.086a.49.49 0 0 1-.519.134L4.671 13.039 2.012 15.96l15.442 5.054 8.558-9.403-2.36-.773-5.689 6.248z"/>
                <path d="M18.582 9.311l1.26-1.384-7.466-2.444-1.26 1.385-2.238-.733 2.524-2.772L23.35 7.274l-2.523 2.772-2.245-.735zm-1.959 5.353l-11.948-3.91L7.199 7.98l2.237.733-1.264 1.388 7.466 2.444 1.264-1.388 2.244.734-2.523 2.773zM10.575 1.786l-8.559 9.402 15.434 5.053 8.56-9.403-15.435-5.052z"/>
            </g>
        </svg>

        <svg v-else-if="name === 'chain-url'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="stroke-path" d="M12 9l1.975-2.025a3.5 3.5 0 10-4.95-4.95L7 4M9 12l-2.025 1.975a3.5 3.5 0 11-4.95-4.95L4 7M5 11l6-6" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <svg v-else-if="name === 'rss-feed'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" fill-rule="evenodd" clip-rule="evenodd" d="M15 15H12.3C12.3 8.8 7.2 3.7 1 3.7V1C8.7 1 15 7.3 15 15ZM3 15C4.10457 15 5 14.1046 5 13C5 11.8954 4.10457 11 3 11C1.89543 11 1 11.8954 1 13C1 14.1046 1.89543 15 3 15ZM7.7 15H10.3C10.3 9.90001 6.1 5.70001 1 5.70001V8.30001C4.7 8.30001 7.7 11.3 7.7 15Z" />
        </svg>

        <svg v-else-if="name === 'product'" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M14.738 0.326C14.549 0.118 14.281 0 14 0H1.99997C1.71897 0 1.45097 0.118 1.26197 0.326C1.07297 0.534 0.978967 0.811 1.00397 1.09L2.00397 12.09C2.03297 12.407 2.21197 12.693 2.48497 12.857L7.48497 15.857C7.64397 15.952 7.82197 16 7.99997 16C8.17797 16 8.35597 15.952 8.51497 15.857L13.515 12.857C13.788 12.693 13.967 12.408 13.996 12.09L14.996 1.09C15.021 0.811 14.928 0.533 14.738 0.326ZM12 4H5.99997V6H12V11.721L7.99997 13.054L3.99997 11.721V9H5.99997V10.279L7.99997 10.946L9.99997 10.279V8H3.99997V2H12V4Z" />
        </svg>

        <svg v-else-if="name === 'format'" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M12 2V13H14V1C14 0.4 13.6 0 13 0H3V2H12ZM1 3H10C10.6 3 11 3.4 11 4V15C11 15.6 10.6 16 10 16H1C0.4 16 0 15.6 0 15V4C0 3.4 0.4 3 1 3ZM2 11H9V5H2V11Z" />
        </svg>

        <svg v-else-if="name === 'size'" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M10 16H15C15.552 16 16 15.552 16 15V1C16 0.448 15.552 0 15 0H10C9.448 0 9 0.448 9 1V15C9 15.552 9.448 16 10 16ZM1 14H6C6.552 14 7 13.553 7 13V3C7 2.447 6.552 2 6 2H1C0.448 2 0 2.447 0 3V13C0 13.553 0.448 14 1 14ZM5 12H2V4H5V12Z" />
        </svg>

        <svg v-else-if="name === 'explorer-play'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M8 16c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8zM6 5v6l5-3-5-3z" />
        </svg>

        <svg v-else-if="name === 'explorer-pause'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M2.5 2h3a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5zm8 0h3a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5z" />
        </svg>

        <svg v-else-if="name === 'user'" viewBox="6 6 12 12" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M9,13h6c2,0,3,1,3,3v2H6v-2C6,14,7,13,9,13z" />
            <circle class="path" cx="12" cy="9" r="3" />
        </svg>

        <svg v-else-if="name === 'google-logo'" viewBox="6 6 12 12" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M12.1,11.3V13H15c-0.1,0.7-0.9,2.2-2.9,2.2c-1.7,0-3.2-1.4-3.2-3.2s1.4-3.2,3.2-3.2c1,0,1.7,0.4,2,0.8l1.4-1.3c-0.9-0.8-2-1.3-3.4-1.3C9.3,7,7,9.2,7,12s2.3,5,5.1,5c2.9,0,4.9-2,4.9-4.9c0-0.3,0-0.6-0.1-0.8H12.1z" />
        </svg>

        <svg v-else-if="name === 'computer'" viewBox="6 6 12 12" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M16,7.7C16,7.3,15.7,7,15.3,7H8.7C8.3,7,8,7.3,8,7.7v4.6C8,12.7,8.3,13,8.7,13h6.6c0.4,0,0.7-0.3,0.7-0.7C16,12.3,16,7.7,16,7.7z M15,12H9V8h6V12z" />
            <path class="path" d="M16,14H8c-0.8,0-1,0.2-1,1v2h10v-2C17,14.2,16.8,14,16,14z M15,16h-2v-1h2V16z M9,15h1v1H9V15z" />
        </svg>

        <svg v-else-if="name === 'monotype-logo'" viewBox="6 6 12 12" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M15,10.6L12,13l-3-2.4V17H7V7l5,3.5L17,7v2v8h-2V10.6z" />
        </svg>

        <svg v-else-if="name === 'folder'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M15 3H8.4L5.7.3C5.5.1 5.3 0 5 0H1C.4 0 0 .4 0 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V4c0-.6-.4-1-1-1z" />
        </svg>

        <svg v-else-if="name === 'psa-folder'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M8.4 3H15c.6 0 1 .4 1 1v11c0 .6-.4 1-1 1H1c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h4c.3 0 .5.1.7.3L8.4 3zm-.241 3.659a2.25 2.25 0 0 1 3.182 3.182L8 13.001 4.659 9.84A2.25 2.25 0 0 1 7.84 6.659c.043.043.078.09.114.136L8 6.853l.045-.058c.036-.047.071-.093.114-.136z" fill-rule="evenodd" />
        </svg>

        <svg v-else-if="name === 'asset-folder'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M6 12h2c.6 0 1-.4 1-1V3.5l3.2 7.9c.2.5.8.8 1.3.5l1.9-.8c.5-.2.8-.8.5-1.3L12.1.5c-.2-.5-.8-.8-1.3-.5L9 .8C8.9.4 8.5 0 8 0H6c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1zM1 12h2c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1H1C.4 0 0 .4 0 1v10c0 .6.4 1 1 1zM16 14H0v2h16v-2z" />
        </svg>

        <svg v-else-if="name === 'pie-chart-old'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M7 1v7.343l.293.293 4.9 4.9A7 7 0 1 1 7 1zm2 0v6.515l4.606 4.606A7.002 7.002 0 0 0 9 1z" />
        </svg>

        <svg v-else-if="name === 'executive-dashboard'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M15 0H1C.4 0 0 .4 0 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm-4.9 10.3L6 7.2 2.7 9.4 1.6 7.7 6 4.8l3.9 2.9L13 4.6 14.4 6l-4.3 4.3z" />
        </svg>

        <svg v-else-if="name === 'group'" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path class="path" d="M9 11.836c0-.604-.265-1.179-.738-1.554C7.539 9.708 6.285 9 4.5 9s-3.039.708-3.762 1.282c-.473.375-.738.95-.738 1.554V13h9v-1.164zM4.5 8C5.88071 8 7 6.88071 7 5.5S5.88071 3 4.5 3 2 4.11929 2 5.5 3.11929 8 4.5 8zM11.5 8C12.8807 8 14 6.88071 14 5.5S12.8807 3 11.5 3 9 4.11929 9 5.5 10.1193 8 11.5 8zM15.262 10.282C14.539 9.708 13.285 9 11.5 9c-.561 0-1.063.075-1.51901.189C10.625 9.909 11 10.836 11 11.836V13h5v-1.164c0-.604-.265-1.179-.738-1.554z"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        name: { type: String, required: true },
    },
}
</script>

<style lang="less" scoped>
@import (reference) '~design-system/less/common';

.icon-wrapper {
    font-size: 0;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.icon-wrapper > svg {
    width: 100%;
    height: 100%;
}

.path {
    fill: currentColor;
}

.stroke-path {
    stroke: currentColor;
}

.relative-wrap {
    position: relative;
}

.spin {
    animation: SPIN 1s ease-out infinite;
}

@keyframes SPIN {
    0% { transform: rotate(0); }
    100% { transform: rotate(720deg); }
}

.new-loading {
    animation: ROTOR 2s ease infinite;

    > g {
        width: 16px;
        height: 16px;
        transform-origin: center center;
        animation: LOADERROTORFULL 1.6s linear infinite;

        > circle {
            animation: DASHLOADER 2s ease infinite;
        }
    }
}

@keyframes ROTOR {
    0%, 50% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes DASHLOADER {
    0%, to {
        stroke-dasharray: 1px, 45px;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 35px, 45px;
        stroke-dashoffset: 0;
    }
}
@keyframes LOADERROTORFULL {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.badge-beta {
    pointer-events: none;
    z-index: 0;
    animation: BADDOWN 0.2s ease forwards;
    background: @orange;
    width: 52px;
    height: 24px;
    border-radius: 24px;
}
.greek {
    animation: GREEKDOWN 0.2s ease forwards;
}
.rest {
    animation: RESTDOWN 0.2s ease-out forwards;
}

@keyframes BADDOWN {
    0%   {
        width: 52px;
    }
    100% {
        width: 24px;
    }
}
@keyframes GREEKDOWN {
    0% {
        transform: translate(0, 0);
        opacity: 0;
    }
    100% {
        transform: translate(-2px, 0);
        opacity: 1;
    }
}
@keyframes RESTDOWN {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }
    100% {
        transform: translate(-2px, 0);
        opacity: 0;
    }
}
</style>
