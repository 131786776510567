<template>
  <div>
    <login-header>
      <template slot="title">Create account</template>
    </login-header>
    <div class="inputs-container">
      <div class="profile-container">
        <input-element theme="light" label="First name" type="text" v-model="firstNameModel" class="profile-container__first-name"></input-element>
        <input-element theme="light" label="Last name" type="text" v-model="lastNameModel" class="profile-container__last-name"></input-element>
      </div>
      <input-element theme="light" label="Email" type="text" v-model="emailModel"></input-element>
      <recaptcha ref="recaptcha" :site-key="platform.recaptchaSiteKey" @verify="setRecaptchaToken"></recaptcha>
      <div class="error-message" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="login-button-container">
        <dialog-button size="small" class="login-dialog-button" @click="signUpHandler" :disabled="!isCreateUserEnabled" :loading="isLoginButtonLoading">Sign up</dialog-button>
      </div>
    </div>
    <login-footer @click="beginFlow('login')">
      <span>Already have a Celtra account? Sign in </span>
    </login-footer>
  </div>
</template>

<script>
import Input from 'design-system/components/Forms/Input/Input.vue'
import DialogButton from 'design-system/components/Buttons/Button/DialogButton.vue'
import LoginHeader from '@/components/LoginHeader.vue'
import LoginFooter from '@/components/LoginFooter.vue'
import Recaptcha from '@/components/Recaptcha.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'LoginWithPasswordStep',
  components: {
    inputElement: Input,
    DialogButton,
    LoginHeader,
    LoginFooter,
    Recaptcha
  },
  computed: {
    ...mapState(['platform']),
    ...mapState('login', ['email', 'isLoginButtonLoading', 'errorMessage', 'firstName', 'lastName']),
    ...mapGetters('login', ['isEmailValid', 'isCreateUserEnabled']),
    emailModel: {
      get: function () {
        return this.email
      },
      set: function (email) {
        this.setEmail(email)
      }
    },
    firstNameModel: {
      get: function () {
        return this.firstName
      },
      set: function (name) {
        this.setFirstName(name)
      }
    },
    lastNameModel: {
      get: function () {
        return this.lastName
      },
      set: function (name) {
        this.setLastName(name)
      }
    }
  },
  methods: {
    ...mapActions('login', [
      'createUser',
      'setRecaptchaToken',
      'setEmail',
      'setFirstName',
      'setLastName',
      'beginFlow'
    ]),
    signUpHandler () {
      this.createUser().finally(() => {
        if (this.$refs.recaptcha) {
          this.$refs.recaptcha.reset()
        }
      })
    }
  }
}
</script>

<style lang="less">
@import '~@/shared/styles.less';
</style>

<style scoped lang="less">
.login-button-container {
  margin-top: 40px;
}

.profile-container {
  display: flex;

  &__first-name {
    margin-right: 8px;
  }

  &__last-name {
    margin-left: 8px;
  }
}
</style>
