<template>
  <div class="settings">
    <div class="header">
      <div class="content-wrapper">
        <div class="header__content">
          <div class="header__content--left" v-if="canReturnToClient">
            <span class="header__back" @click="returnToClient"><icon name="left" class="header__back-icon"></icon>Back</span>
            <span class="header__divider">/</span>
            <span class="header__settings">User settings</span>
          </div>
          <div class="header__content--left" v-else>
            <span class="header__settings header__settings--active">User settings</span>
          </div>
          <div class="header__content--right" v-if="me">
            <span class="header__link" @click="logout">Sign out</span>
            <a class="header__link" href="https://support.celtra.com">Support</a>
          </div>
        </div>
      </div>
    </div>
    <container-card class="content-container" v-if="me">
      <div class="title">Welcome, {{ me.firstName }} {{ me.lastName }}</div>
      <div class="subtitle" v-if="isSsoEnabled">Your personal info and security is managed by <strong>{{ domainSecurityConstraint.label }}</strong>.</div>
      <div class="subtitle" v-else>Manage your personal info and security.</div>
      <div class="divider"></div>
      <div class="section-title">
        <span class="section-title__icon">
          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 7c1.6569 0 3-1.34315 3-3s-1.3431-3-3-3-3 1.34315-3 3 1.3431 3 3 3z" fill="#454555"/><path d="M23.231 7.784c-.514.439-1.113.771-1.774.974C21.812 9.792 22 10.883 22 12c0 5.514-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2c1.117 0 2.207.189 3.242.543.203-.662.535-1.261.975-1.775C14.88.266 13.458 0 12 0 5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12c0-1.458-.266-2.88-.769-4.216z" fill="#454555"/><path d="M12 11c1.6569 0 3-1.34315 3-3s-1.3431-3-3-3-3 1.34315-3 3 1.3431 3 3 3zM18 17H6c0-2.761 2.239-5 5-5h2c2.761 0 5 2.239 5 5z" fill="#454555"/></svg>
        </span>
        <span>Personal information</span>
      </div>
      <div class="first-last-name-inputs__wrapper input-wrapper">
        <input-element class="first-last-name-inputs__first-name" label="First name" theme="light" v-model="firstNameModel" :disabled="isSsoEnabled"></input-element>
        <input-element class="first-last-name-inputs__last-name" label="Last name" theme="light" v-model="lastNameModel" :disabled="isSsoEnabled"></input-element>
      </div>
      <div class="input-wrapper">
        <input-element label="Email address" theme="light" v-model="emailModel" :disabled="isSsoEnabled" :helper-text="emailHelperText" :error="!isEmailValid ? 'Invalid email format' : null"></input-element>
      </div>
      <div class="divider"></div>
      <div class="section-title">
        <span class="section-title__icon"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 10.726V6c0-3.309-2.691-6-6-6S6 2.691 6 6v4.726C4.759 12.136 4 13.979 4 16c0 4.411 3.589 8 8 8s8-3.589 8-8c0-2.021-.759-3.864-2-5.274zm-5 7.09V20h-2v-2.184C9.839 17.402 9 16.302 9 15c0-1.654 1.346-3 3-3s3 1.346 3 3c0 1.302-.838 2.402-2 2.816zm3-8.734C14.822 8.398 13.458 8 12 8c-1.458 0-2.822.398-4 1.082V6c0-2.206 1.794-4 4-4s4 1.794 4 4v3.082z" fill="#454555"/></svg></span>
        <span>Security</span>
      </div>
      <template v-if="isSsoEnabled">
        <div class="input-wrapper">
          <input-element label="Password" theme="light" type="password" :disabled="true"></input-element>
        </div>
      </template>
      <template v-else>
        <div class="input-wrapper">
          <input-element label="Current password" theme="light" type="password" v-model="oldPasswordModel" :error="passwordChangeErrorOldPassword"></input-element>
        </div>
        <div class="input-wrapper--10m">
          <input-element label="New password" helper-text="Use a password at least 8 characters long with mixed letters and numbers." theme="light" type="password" v-model="newPasswordModel" :error="passwordChangeErrorNewPassword"></input-element>
        </div>

        <div class="input-wrapper">
          <input-element label="Confirm new password" theme="light" type="password" :error="passwordConfirmError" v-model="confirmNewPasswordModel"></input-element>
        </div>
        <div class="successfully-updated-notice" v-if="isProfileUpdateSuccessful">
          <span class="successfully-updated-notice__icon">
            <svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path d="M8.5 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm-1 11.4L4.1 8l1.4-1.4 2 2 4-4L12.9 6l-5.4 5.4z" fill="#17E387"/></g><defs><clipPath id="clip0"><path fill="#fff" d="M.5 0h16v16H.5z"/></clipPath></defs></svg>
          </span>
          <span>Your information was successfully updated.</span>
        </div>
      </template>
      <dialog-button class="login-dialog-button save-changes-button" size="small" :disabled="!canSaveChanges" @click="saveProfileChanges" :loading="isProfileUpdateButtonLoading">Save changes</dialog-button>
    </container-card>
    <container-card class="recently-used-clients" v-if="recentlyUsedClients.length">
      <span>Return to platform:</span>
      <a class="recently-used-clients__link" v-for="client in recentlyUsedClients" :key="client.id" :href="client.link">
        <img class="recently-used-clients__client-image" :src="client.image"/>
      </a>
    </container-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import DialogButton from 'design-system/components/Buttons/Button/DialogButton.vue'
import Input from 'design-system/components/Forms/Input/Input.vue'
import Icon from 'design-system/components/Icon/Icon.vue'
import ContainerCard from '@/components/ContainerCard.vue'

export default {
  components: {
    DialogButton,
    Icon,
    ContainerCard,
    inputElement: Input
  },
  computed: {
    ...mapState(['me']),
    ...mapState('settings', ['firstName', 'lastName', 'email', 'oldPassword', 'newPassword', 'confirmNewPassword', 'passwordChangeErrorNewPassword', 'passwordChangeErrorOldPassword', 'pendingEmail', 'domainSecurityConstraint', 'emailChangeError', 'isProfileUpdateButtonLoading', 'isProfileUpdateSuccessful', 'isEmailValid', 'recentlyUsedClients']),
    ...mapGetters('settings', ['canSaveChanges', 'isConfirmNewPasswordValid', 'isSsoEnabled', 'canReturnToClient']),
    firstNameModel: {
      get: function () {
        return this.firstName
      },
      set: function (name) {
        this.setFirstName(name)
      }
    },
    lastNameModel: {
      get: function () {
        return this.lastName
      },
      set: function (name) {
        this.setLastName(name)
      }
    },
    emailModel: {
      get: function () {
        return this.email
      },
      set: function (email) {
        this.setEmail(email)
      }
    },
    oldPasswordModel: {
      get: function () {
        return this.oldPassword
      },
      set: function (password) {
        this.setOldPassword(password)
      }
    },
    newPasswordModel: {
      get: function () {
        return this.newPassword
      },
      set: function (password) {
        this.setNewPassword(password)
      }
    },
    confirmNewPasswordModel: {
      get: function () {
        return this.confirmNewPassword
      },
      set: function (password) {
        this.setConfirmNewPassword(password)
      }
    },
    emailHelperText () {
      return this.pendingEmail ? `Check your email (${this.pendingEmail}) to confirm your new address.` : null
    },
    passwordConfirmError () {
      return !this.isConfirmNewPasswordValid && ![null, ''].includes(this.confirmNewPasswordModel) ? 'Passwords do not match.' : null
    }
  },
  methods: {
    ...mapActions(['logout']),
    ...mapActions('settings', ['setFirstName', 'setLastName', 'setEmail', 'setOldPassword', 'setNewPassword', 'setConfirmNewPassword', 'saveProfileChanges', 'returnToClient'])
  }
}
</script>

<style lang="less">
  @import '~@/shared/styles.less';
</style>

<style scoped lang="less">
@import (reference) '~@/design-system/src/less/variables.less';
.header {
  width: 100%;
  height: 50px;
  background-color: @void;

  &__content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content--left {
    display: flex;
    align-items: center;
  }

  &__back {
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: @cement;
    font-size: 12px;
    text-transform: uppercase;
    .medium-font();
  }

  &__back-icon {
    margin-right: 10px;
  }

  &__divider {
    color: @charcoal;
    font-size: 14px;
    height: 18px;
    .medium-font();
    margin-left: 15px;
    margin-right: 15px;
  }

  &__settings {
    color: @white;
    opacity: 0.5;
    font-size: 14px;
    .medium-font();

    &--active {
      opacity: 1;
      color: @cement;
    }
  }

  &__link {
    height: 30px;
    display: inline-block;
    line-height: 30px;
    margin-left: 25px;
    cursor: pointer;
    color: @white;
    opacity: 0.5;
    font-size: 14px;
    text-decoration: none;
    .medium-font();
  }
}
.content-wrapper {
  width: 1230px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}

.content-container {
  width: 728px;
  padding: 36px 64px;
  margin: 64px auto 16px auto;
}

.recently-used-clients {
  display: flex;
  align-items: center;
  width: 728px;
  margin: 16px auto 64px auto;
  padding: 16px 64px;
  color: @charcoal;
  font-size: 14px;
  .regular-font();
  &__link {
    height: 32px;
    margin-left: 24px;
    margin-right: auto;
  }
  &__client-image {
    height: inherit;
  }
}

.divider {
  height: 1px;
  background-color: @silver;
  margin-top: 26px;
  margin-bottom: 26px;
}

.title {
  color: @slate;
  font-size: 32px;
  .bold-font();
}

.section-title {
  display: flex;
  color: @charcoal;
  .bold-font();
  font-size: 20px;

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
}

.first-last-name-inputs {
  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__first-name {
    margin-right: 12px;
  }

  &__last-name {
    margin-left: 12px;
  }
}

.successfully-updated-notice {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  .regular-font();
  font-size: 12px;
  color: @charcoal;

  &__icon {
    margin-right: 5px;
    width: 16px;
  }
}

.dropdown__activator {
  display: flex;
  align-items: center;
  &:hover {
    .dropdown__label, .dropdown__caret {
      color: @silver;
    }
  }
}

.dropdown {
  &__label {
    color: @dust;
    transition: color 150ms ease;
    .regular-font();
    font-size: 14px;
  }

  &__caret {
    color: @dust;
    transition: color 150ms ease;
    width: 12px;
    height: 5px;
    padding-top: 3px;
  }
}

.save-changes-button {
  margin-top: 24px;
}

.input-wrapper {
  margin-top: 22px;
}
</style>

<style lang="less">
@import '~@/shared/styles.less';

.dropdown .dropdown__dialog.dropdown__dialog--override {
  width: 140px;
}
</style>
