<template>
  <div id="login">
  <div id="celtra-logo"></div>
    <container-card>
        <transition name="login-steps" mode="out-in" tag="div" class="login-steps">
          <login-step key="login" v-if="stepId === 'login'"></login-step>
          <login-with-password-step key="password" v-else-if="stepId === 'loginWithPassword'"></login-with-password-step>
          <authorization-code-step key="authorization-code" v-else-if="stepId === 'authorizationCode'"></authorization-code-step>
          <choose-password-step key="choose-password" v-else-if="stepId === 'choosePassword'"></choose-password-step>
          <password-reset-enter-email-step key="password-reset-email" v-else-if="stepId === 'passwordResetEnterEmail'"></password-reset-enter-email-step>
          <signup-step v-else key="signup"></signup-step>
        </transition>
    </container-card>
  </div>
</template>

<script>
import ContainerCard from '@/components/ContainerCard.vue'
import LoginStep from '@/components/login/LoginStep.vue'
import LoginWithPasswordStep from '@/components/login/LoginWithPasswordStep.vue'
import SignupStep from '@/components/login/SignupStep.vue'
import AuthorizationCodeStep from '@/components/login/AuthorizationCodeStep.vue'
import ChoosePasswordStep from '@/components/login/ChoosePasswordStep.vue'
import PasswordResetEnterEmailStep from '@/components/login/PasswordResetEnterEmailStep.vue'

import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    ContainerCard,
    LoginWithPasswordStep,
    LoginStep,
    SignupStep,
    AuthorizationCodeStep,
    ChoosePasswordStep,
    PasswordResetEnterEmailStep
  },
  computed: {
    ...mapState('login', ['stepId'])
  }
}
</script>

<style scoped lang="less">
@import (reference) '~@/design-system/src/less/variables.less';
@import (reference) '~@/shared/variables.less';

@media(min-width:600px) {
  #celtra-logo {
    position: fixed;
    left: 64px;
    top: 56px;
    width: 125px;
    height: 60px;
    background: url('../assets/celtra-logo-black.svg') center no-repeat;
  }
}

#login {
  width: 100%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;

  @media(min-height:700px) {
    /* Do not display landscape img when in very small viewport, e.g. login opened in popup */
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    background: url('../assets/auth-hero-image.png') center no-repeat;
  }

  @media(min-width:1230px) {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 97.36%, @lollipop-pink 99.93%);
      pointer-events: none;
    }
  }
}

.login-steps {
  margin-top: 40px;
}
</style>

<style lang="less">

  .login-steps-enter-active, .login-steps-leave-active {
    transition: all .3s cubic-bezier(.33,1,.68,1) ;
    max-height: 470px;
    .inputs-container {
      transition: all .15s cubic-bezier(.33,1,.68,1);
    }
  }

  .login-steps-enter, .login-steps-leave-to {
    max-height: 250px;
    .inputs-container, .login-footer {
      opacity: 0;
    }
  }

  .login-steps-enter-active .inputs-container,
  .login-steps-enter-active .login-footer {
    transition-delay: .15s;
  }

  .login-steps-leave-active {
    transition-delay: .15s;
  }
</style>
