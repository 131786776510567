import Vue from 'vue'
import Vuex from 'vuex'
import api from '../apiClient.js'
import { loginPaths } from '../constants'
import router from '../../router/index.js'
import { isEmailValid } from '../../utils/input-validation.js'

Vue.use(Vuex)

function getState () {
  return {
    flow: 'login',
    stepId: 'login',
    email: null,
    password: null,
    confirmPassword: null,
    firstName: null,
    lastName: null,
    recaptchaToken: null,
    isLoginButtonLoading: false,
    errorMessage: null,
    authorizationCode: null,
    unsuccessfulLoginAttempts: 0,
    didVerifyEmailWithVerificationToken: false
  }
}

export default {
  state: getState(),
  namespaced: true,
  mutations: {
    SET_STEP_ID (state, stepId) {
      state.errorMessage = null
      state.stepId = stepId
    },
    SET_IS_LOGIN_BUTTON_LOADING (state, isLoading) {
      state.isLoginButtonLoading = isLoading
    },
    SET_EMAIL (state, email) {
      state.email = email
    },
    SET_PASSWORD (state, password) {
      state.password = password
    },
    SET_CONFIRM_PASSWORD (state, password) {
      state.confirmPassword = password
    },
    SET_FIRST_NAME (state, name) {
      state.firstName = name
    },
    SET_LAST_NAME (state, name) {
      state.lastName = name
    },
    SET_RECAPTCHA_TOKEN (state, token) {
      state.recaptchaToken = token
    },
    SET_ERROR_MESSAGE (state, message) {
      state.errorMessage = message
    },
    SET_AUTHORIZATION_CODE (state, code) {
      state.authorizationCode = code
    },
    SET_FLOW (state, flow) {
      state.flow = flow
    },
    SET_UNSUCCESSFUL_LOGIN_ATTEMPTS (state, attempts) {
      state.unsuccessfulLoginAttempts = attempts
    },
    RESET_UNSUCCESSFUL_LOGIN_ATTEMPTS (state) {
      state.unsuccessfulLoginAttempts = 0
    },
    SET_DID_VERIFY_EMAIL_WITH_VERIFICATION_TOKEN (state, didVErify) {
      state.didVerifyEmailWithVerificationToken = didVErify
    },
    RESET_STATE (state) {
      Object.assign(state, JSON.parse(JSON.stringify(getState())))
    }
  },
  actions: {
    initialize ({ dispatch, commit, rootState }) {
      dispatch('getMe', {}, { root: true })
        .then(() => router.push('/settings'))
        .catch(() => {
          if (rootState.urlParams.beginFlow === 'signup' || rootState.urlParams.email) {
            dispatch('beginFlow', 'signup')
          }
          if (rootState.urlParams.email) {
            commit('SET_EMAIL', rootState.urlParams.email)
          }
          if (rootState.urlParams.firstName) {
            commit('SET_FIRST_NAME', rootState.urlParams.firstName)
          }
          if (rootState.urlParams.lastName) {
            commit('SET_LAST_NAME', rootState.urlParams.lastName)
          }
          if (rootState.urlParams.email && /\S+@\S+\.\S+/.test(rootState.urlParams.email)) {
            dispatch('loadDomainConstraintForDomain', rootState.urlParams.email.split('@')[1])
              .then(constraint => {
                if (constraint.isSso) {
                  dispatch('beginFlow', 'login')
                }
              })
          }
        })
    },
    beginFlow ({ state, commit, dispatch, getters, rootState }, flowId) {
      if (flowId === 'resetPassword') {
        commit('SET_PASSWORD', null)
      }
      commit('SET_FLOW', flowId)
      commit('SET_STEP_ID', getters.availableSteps[0])
    },
    goToNextStep ({ state, commit, getters, dispatch }) {
      const currentStepIndex = getters.availableSteps.indexOf(state.stepId)
      if (currentStepIndex < getters.availableSteps.length - 1) {
        commit('SET_STEP_ID', getters.availableSteps[currentStepIndex + 1])
      } else {
        return dispatch('redirectAfterLogin')
      }
    },
    determineLoginMethod ({ state, commit, dispatch, rootState }) {
      commit('SET_IS_LOGIN_BUTTON_LOADING', true)
      dispatch('loadDomainConstraintForDomain', state.email.split('@')[1])
        .then((constraint) => {
          if (constraint.isSso) {
            let url = `/api/ssoLoginRedirect/${constraint.authTypeId}?email=${state.email}`
            if (rootState.urlParams.returnToClient) {
              const client = rootState.clients.find(client => client.id === rootState.urlParams.returnToClient)
              if (client && client.applicationUrl) {
                url += `&state=${encodeURIComponent(client.applicationUrl)}`
              }
            } else if (rootState.urlParams.redirUrl) {
              url += `&state=${encodeURIComponent(rootState.urlParams.redirUrl)}`
            }
            window.location = url
          } else {
            commit('SET_IS_LOGIN_BUTTON_LOADING', false)
            dispatch('beginFlow', 'passwordLogin')
          }
        })
    },
    redirectAfterLogin ({ rootState }) {
      // The only allowed redirect URL after login should be https://<platformDomain>/api/authorize and  https://<platformDomain>/settings.
      // The client (e.g. MAB) redirects user to /authorize endpoint for login to MAB and if the user isn't logged in to auth service
      // he has to log in here first, is then redirected back to authorize endpoint and from there back to MAB.
      // We disallow all other redirect URL to prevent stuff like https://auth.celtra.com/?redirUrl=fakeCeltraCaLookingWebsite
      // It's enough to check the hostname of redirect url
      // Or we can also redirect back to client from the redirectToClient param
      if (rootState.urlParams.returnToClient) {
        const client = rootState.clients.find(client => client.id === rootState.urlParams.returnToClient)
        if (client && client.applicationUrl) {
          window.location.href = client.applicationUrl
        }
      }
      if (rootState.urlParams.redirUrl) {
        const url = new URL(rootState.urlParams.redirUrl)
        if (url.hostname === rootState.platform.platformDomain) {
          window.location = rootState.urlParams.redirUrl
          return
        }
      }
      router.push('/settings')
    },
    requestPasswordReset ({ state, commit, dispatch }) {
      commit('SET_IS_LOGIN_BUTTON_LOADING', true)
      api.post('/passwordResetRequest', {
        userEmail: state.email
      }).then(() => {
        dispatch('goToNextStep')
      }).catch(error => {
        commit('SET_ERROR_MESSAGE', error.message)
      }).finally(() => {
        commit('SET_IS_LOGIN_BUTTON_LOADING', false)
      })
    },
    createUser ({ state, commit, dispatch, getters }) {
      commit('SET_IS_LOGIN_BUTTON_LOADING', true)
      commit('SET_ERROR_MESSAGE', null)
      return api.post('/users', {
        firstName: state.firstName,
        lastName: state.lastName,
        email: state.email,
        recaptchaToken: state.recaptchaToken,
        doNotSendAuthorizationKey: getters.isEmailVerificationTokenPresent
      }).then(() => {
        if (getters.isEmailVerificationTokenPresent) {
          return dispatch('verifyEmailWithVerificationToken').then(() => dispatch('goToNextStep'))
        } else {
          return dispatch('goToNextStep')
        }
      }).catch(error => {
        commit('SET_ERROR_MESSAGE', error.message)
      }).finally(() => {
        commit('SET_IS_LOGIN_BUTTON_LOADING', false)
      })
    },
    verifyEmailWithVerificationToken ({ state, rootState, commit }) {
      return api.post('/session', {
        email: rootState.urlParams.email,
        emailVerificationToken: rootState.urlParams.emailVerificationToken,
        emailVerificationClientId: rootState.urlParams.clientId
      }).then(() => {
        commit('SET_DID_VERIFY_EMAIL_WITH_VERIFICATION_TOKEN', true)
      }).catch(() => {
        // If email verification with verification token fails we have to send authorization key, as it was not sent
        // when creating the user
        return api.post('/passwordResetRequest', {
          userEmail: state.email,
          signupAuthorizationKey: true
        })
      })
    },
    validateAuthorizationCode ({ state, commit, dispatch }) {
      commit('SET_IS_LOGIN_BUTTON_LOADING', true)
      commit('SET_ERROR_MESSAGE', null)
      return api.post('/session', {
        email: state.email,
        passwordClaimAuthKey: state.authorizationCode,
        recaptchaToken: state.recaptchaToken
      }).then(() => {
        commit('RESET_UNSUCCESSFUL_LOGIN_ATTEMPTS')
        dispatch('goToNextStep')
      })
        .catch(error => {
          commit('SET_RECAPTCHA_TOKEN', null)
          commit('SET_UNSUCCESSFUL_LOGIN_ATTEMPTS', state.unsuccessfulLoginAttempts + 1)
          if (error.code === 'RECAPTCHA_REQUIRED') {
            commit('SET_UNSUCCESSFUL_LOGIN_ATTEMPTS', 5)
          }
          commit('SET_ERROR_MESSAGE', error.message)
        })
        .finally(() => {
          commit('SET_IS_LOGIN_BUTTON_LOADING', false)
        })
    },
    savePassword ({ state, commit, dispatch }) {
      commit('SET_IS_LOGIN_BUTTON_LOADING', true)
      commit('SET_ERROR_MESSAGE', null)
      return api.post('/passwordClaimRequest', {
        newPassword: state.password,
        passwordClaimAuthKey: state.authorizationCode
      }).then(() => dispatch('goToNextStep'))
        .catch(error => {
          commit('SET_ERROR_MESSAGE', error.message)
          commit('SET_IS_LOGIN_BUTTON_LOADING', false)
        })
    },
    loginWithPassword ({ state, commit, dispatch }) {
      commit('SET_IS_LOGIN_BUTTON_LOADING', true)
      commit('SET_ERROR_MESSAGE', null)
      return api.post('/session', {
        email: state.email,
        password: state.password,
        recaptchaToken: state.recaptchaToken
      }).then(() => {
        commit('RESET_UNSUCCESSFUL_LOGIN_ATTEMPTS')
        dispatch('goToNextStep')
      })
        .catch(error => {
          commit('SET_RECAPTCHA_TOKEN', null)
          commit('SET_UNSUCCESSFUL_LOGIN_ATTEMPTS', state.unsuccessfulLoginAttempts + 1)
          if (error.code === 'RECAPTCHA_REQUIRED') {
            commit('SET_UNSUCCESSFUL_LOGIN_ATTEMPTS', 5)
          }
          commit('SET_ERROR_MESSAGE', error.message)
        })
        .finally(() => {
          commit('SET_IS_LOGIN_BUTTON_LOADING', false)
        })
    },
    setAuthorizationCode ({ commit }, code) {
      commit('SET_AUTHORIZATION_CODE', code)
    },
    setEmail ({ commit }, email) {
      commit('SET_EMAIL', email)
    },
    setPassword ({ commit }, password) {
      commit('SET_PASSWORD', password)
    },
    setConfirmPassword ({ commit }, password) {
      commit('SET_CONFIRM_PASSWORD', password)
    },
    setFirstName ({ commit }, name) {
      commit('SET_FIRST_NAME', name)
    },
    setLastName ({ commit }, name) {
      commit('SET_LAST_NAME', name)
    },
    setRecaptchaToken ({ commit }, token) {
      commit('SET_RECAPTCHA_TOKEN', token)
    },
    loadDomainConstraintForDomain (store, domain) {
      return api.get(`/domainSecurityConstraints/${domain}`)
    },
    resetState ({ commit }) {
      commit('RESET_STATE')
    }
  },
  getters: {
    availableSteps (state) {
      if (!state.flow) {
        return ['login']
      }
      return loginPaths.find(path => path.flow === state.flow && path.condition(state)).steps
    },
    isLoginWithPasswordEnabled (state, getters) {
      return getters.isEmailValid && !isEmpty(state.password) && (!getters.isRecaptchaRequiredForLogin || state.recaptchaToken !== null)
    },
    isCreateUserEnabled (state, getters) {
      return getters.isEmailValid && !isEmpty(state.firstName) && !isEmpty(state.lastName) && !isEmpty(state.recaptchaToken)
    },
    isEmailValid (state) {
      return !isEmpty(state.email) && isEmailValid(state.email)
    },
    isAuthorizationCodeEmpty (state) {
      return isEmpty(state.authorizationCode)
    },
    isAuthorizationCodeSubmitEnabled (state, getters) {
      return !getters.isAuthorizationCodeEmpty && (!getters.isRecaptchaRequiredForLogin || state.recaptchaToken !== null)
    },
    isConfirmPasswordDifferent (state) {
      return !isEmpty(state.password) && !isEmpty(state.confirmPassword) && state.password !== state.confirmPassword
    },
    isSavePasswordEnabled (state, getters) {
      return !isEmpty(state.password) && !isEmpty(state.confirmPassword) && !getters.isConfirmPasswordDifferent
    },
    isRecaptchaRequiredForLogin (state) {
      return state.unsuccessfulLoginAttempts >= 5
    },
    isEmailVerificationTokenPresent (state, getters, rootState) {
      return !!(rootState.urlParams.clientId && rootState.urlParams.email && rootState.urlParams.emailVerificationToken)
    },
    isSelfSignUpAvailable (state, getters, rootState) {
      return rootState.urlParams.isSelfSignUpAvailable === '1'
    }
  }
}

function isEmpty (v) { return !v || v === '' }
