<template>
<div id="recaptcha"></div>
</template>

<script>
/* global grecaptcha */
export default {
  props: {
    siteKey: { type: String, required: true }
  },
  mounted () {
    const self = this
    grecaptcha.render('recaptcha', {
      sitekey: this.siteKey,
      callback: function (token) {
        self.$emit('verify', token)
      }
    })
  },
  methods: {
    reset () {
      grecaptcha.reset()
    }
  }
}
</script>

<style scoped>
#recaptcha {
  margin-top: 8px;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
