<template>
  <div>
    <login-header>
      <template slot="title">Authorization code</template>
      <template slot="subtitle">Check your email for authorization code</template>
    </login-header>
    <div class="inputs-container">
      <input-element theme="light" ref="code" label="Authorization code" v-model="authorizationCodeModel" @keyup.enter="handleEnter"></input-element>
      <recaptcha ref="recaptcha" v-if="isRecaptchaRequiredForLogin" :site-key="platform.recaptchaSiteKey" @verify="setRecaptchaToken"></recaptcha>
      <div class="error-message" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="login-button-container">
        <dialog-button size="small" class="login-dialog-button" @click="validateAuthorizationCodeHandler" :disabled="!isAuthorizationCodeSubmitEnabled" :loading="isLoginButtonLoading">Continue</dialog-button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from 'design-system/components/Forms/Input/Input.vue'
import DialogButton from 'design-system/components/Buttons/Button/DialogButton.vue'
import LoginHeader from '@/components/LoginHeader.vue'
import Recaptcha from '@/components/Recaptcha.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'LoginStep',
  components: {
    Recaptcha,
    inputElement: Input,
    LoginHeader,
    DialogButton
  },
  computed: {
    ...mapState(['platform']),
    ...mapState('login', ['authorizationCode', 'isLoginButtonLoading', 'errorMessage']),
    ...mapGetters('login', ['isAuthorizationCodeSubmitEnabled', 'isAuthorizationCodeEmpty', 'isRecaptchaRequiredForLogin']),
    authorizationCodeModel: {
      get: function () {
        return this.authorizationCode
      },
      set: function (code) {
        this.setAuthorizationCode(code)
      }
    }
  },
  mounted () {
    this.$refs.code.focus()
  },
  methods: {
    ...mapActions('login', [
      'validateAuthorizationCode',
      'setAuthorizationCode',
      'setRecaptchaToken'
    ]),
    handleEnter () {
      if (!this.isAuthorizationCodeEmpty) {
        this.validateAuthorizationCodeHandler()
      }
    },
    validateAuthorizationCodeHandler () {
      this.validateAuthorizationCode().then(() => {
        if (this.$refs.recaptcha) {
          this.$refs.recaptcha.reset()
        }
      })
    }
  }
}
</script>

<style lang="less">
@import '~@/shared/styles.less';
</style>

<style scoped lang="less">

.login-button-container {
  margin-top: 40px;
}
</style>
