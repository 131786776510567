import Vue from 'vue'
import Vuex from 'vuex'
import api from './apiClient.js'
import loginModule from './modules/login.js'
import settingsModule from './modules/settings.js'
import router from '../router/index.js'

Vue.use(Vuex)

function getState () {
  return {
    platform: null,
    me: null,
    urlParams: {},
    didVerifyEmailWithVerificationToken: false,
    clients: []
  }
}

export default new Vuex.Store({
  state: getState(),
  mutations: {
    SET_PLATFORM (state, platform) {
      state.platform = platform
    },
    SET_ME (state, me) {
      state.me = me
    },
    SET_URL_PARAMS (state, urlParams) {
      state.urlParams = urlParams
    },
    SET_CLIENTS (state, clients) {
      state.clients = clients
    }
  },
  actions: {
    initialize ({ commit, dispatch }) {
      dispatch('parseParamsFromUrl')
      dispatch('login/initialize')
      return Promise.all([
        api.get('/').then(platform => commit('SET_PLATFORM', platform)),
        dispatch('loadClients')
      ])
    },
    loadClients ({ commit }) {
      return api.get('/clients').then(response => commit('SET_CLIENTS', response))
    },
    getMe ({ state, commit, dispatch }) {
      return api.get('/me').then(me => {
        commit('SET_ME', me)
        return dispatch('settings/initialize')
      })
    },
    parseParamsFromUrl ({ commit }) {
      const searchParams = (new URL(window.location.href)).searchParams
      const parsedParameters = ['emailChangeKey', 'returnToClient']
      const urlParams = {}
      parsedParameters.forEach(param => {
        if (searchParams.has(param)) {
          urlParams[param] = searchParams.get(param)
        }
      })
      if (searchParams.has('redirUrl')) {
        urlParams.redirUrl = searchParams.get('redirUrl')
        // Email verification tokens are passed like this:
        // URL in invite email:                             https://test.celtra.com?email=john@celtra.com&emailVerificationToken=<token>
        // Redirected to auth service /authorize for login: https://auth.celtra.com/api/authorize?state=base64encode(redirUrl,authState)&email=kosir.jakob@gmail.com&&emailVerificationToken=<token>&redir_uri=https://hub.celtra.com/api/authCallback
        // Redirected to auth service login page (here):    https://auth.celtra.com/?redirUrl=urlencode(https://auth.celtra.com/api/authorize?state=base64encode(redirUrl,authState)&email=kosir.jakob@gmail.com&&emailVerificationToken=<token>&redir_uri=https://hub.celtra.com/api/authCallback)
        // So here we parse emailVerificationTokens from state in redirUrl :)
        const authorizeSearchParams = getSearchParams(searchParams.get('redirUrl'))
        if (authorizeSearchParams) {
          urlParams.clientId = authorizeSearchParams.get('client_id')
          urlParams.email = authorizeSearchParams.get('email')
          urlParams.emailVerificationToken = authorizeSearchParams.get('emailVerificationToken')
          urlParams.beginFlow = authorizeSearchParams.get('beginFlow')
          urlParams.firstName = authorizeSearchParams.get('firstName')
          urlParams.lastName = authorizeSearchParams.get('lastName')
          urlParams.isSelfSignUpAvailable = authorizeSearchParams.get('isSelfSignUpAvailable')
        }
      }
      commit('SET_URL_PARAMS', urlParams)

      function getSearchParams (url) {
        try {
          return (new URL(url)).searchParams
        } catch {
          return null
        }
      }
    },
    logout ({ commit }) {
      commit('SET_ME', null)
      router.push('/logout')
    }
  },
  modules: {
    login: loginModule,
    settings: settingsModule
  }
})
