'login', <template>
  <div>
    <login-header>
      <template slot="title">Password reset</template>
      <template slot="subtitle">Enter your email to continue</template>
    </login-header>
    <div class="inputs-container">
      <input-element theme="light" label="Email" v-model="emailModel"></input-element>
      <div class="error-message" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <div class="login-button-container">
        <dialog-button size="small" class="login-dialog-button" @click="requestPasswordReset" :disabled="!isEmailValid" :loading="isLoginButtonLoading">Continue</dialog-button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from 'design-system/components/Forms/Input/Input.vue'
import DialogButton from 'design-system/components/Buttons/Button/DialogButton.vue'
import LoginHeader from '@/components/LoginHeader.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'LoginStep',
  components: {
    inputElement: Input,
    LoginHeader,
    DialogButton
  },
  computed: {
    ...mapState('login', ['email', 'isLoginButtonLoading', 'errorMessage']),
    ...mapGetters('login', ['isEmailValid']),
    emailModel: {
      get: function () {
        return this.email
      },
      set: function (email) {
        this.setEmail(email)
      }
    }
  },
  methods: {
    ...mapActions('login', [
      'requestPasswordReset',
      'setEmail'
    ])
  }
}
</script>

<style lang="less">
@import '~@/shared/styles.less';
</style>

<style scoped lang="less">

.login-button-container {
  margin-top: 40px;
}
</style>
