import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Settings from '../views/Settings.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home'
  },
  {
    path: '/logout',
    name: 'Logout'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Settings') {
    store.dispatch('settings/resetState')
    return store.dispatch('getMe').then(() => {
      return next()
    }).catch(() => {
      return next({ name: 'Login' })
    })
  } else if (to.name === 'Login') {
    store.dispatch('login/resetState')
    return next()
  } else if (to.name === 'Logout') {
    next()
    // The purpose of this is to make a GET /api/logout with Http Referer = <platformDomain>/logout
    // which is the only referer we allow on /api/logout.
    // This prevents stuff like embedding <img src='https://auth.celtra.com/api/logout'>
    // and browsers prefetching logout urls
    window.location.pathname = '/api/logout'
  } else {
    return store.dispatch('getMe').then(() => {
      return next({ name: 'Settings' })
    }).catch(() => {
      return next({ name: 'Login' })
    })
  }
})

export default router
